import { createSlice } from "@reduxjs/toolkit";

const sammetaSlice = createSlice({
  name: "sammeta",
  initialState: {
    api_link: "https://api.virtualchennai.in/",
    theme: "dark",
    dark: "#18222e",
    light: "#ffffff",
    dark_font: "#ffffff",
    light_font: "#18222e",
    // DashBoard Data
    total_user: 0,
    total_site_views: 0,
    total_sites: 0,
    dashboard_graph_data: {
      lables: [],
      data: [],
      backgroundColor: [],
    },
    user_graph_data: {
      lables: [],
      data: [],
      backgroundColor: [],
      user_name: "",
      user_email: "",
      user_mobile_number: "",
      user_status: false,
      user_active: false,
    },
    user_data: [],
    user_mobile_number: "",
  },
  reducers: {
    // Theme
    setTheme(state, action) {
      state.theme = action.payload;
    },
    // Set Total user
    setTotalUser(state, action) {
      state.total_user = action.payload;
    },
    // Set Total Site Views
    setTotalSiteViews(state, action) {
      state.total_site_views = action.payload;
    },
    // Set Total Sites
    setTotalSite(state, action) {
      state.total_sites = action.payload;
    },
    // Set Dashboard Graph Labels
    setDashboardGraphLabels(state, action) {
      const generateRandomColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
      };

      const uniqueColors = new Set();

      while (uniqueColors.size < action.payload.length) {
        uniqueColors.add(generateRandomColor());
      }

      const colorArray = Array.from(uniqueColors);

      state.dashboard_graph_data["labels"] = action.payload;
      state.dashboard_graph_data["backgroundColor"] = colorArray;
    },
    // Set User Graph Labels
    setSingleUserGraphLabels(state, action) {
      const generateRandomColor = () => {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);
        return `#${randomColor}`;
      };

      const uniqueColors = new Set();

      while (uniqueColors.size < action.payload.length) {
        uniqueColors.add(generateRandomColor());
      }

      const colorArray = Array.from(uniqueColors);

      state.user_graph_data["labels"] = action.payload;
      state.user_graph_data["backgroundColor"] = colorArray;
    },
    // Set Dashboard Graph Data
    setDashboardGraphData(state, action) {
      state.dashboard_graph_data["data"] = action.payload;
    },
    // Set User Graph Data
    setSingleUserGraphData(state, action) {
      state.user_graph_data["data"] = action.payload;
    },
    // Set single User Data
    setSingleUserData(state, action) {
      state.user_graph_data["_id"] = action.payload._id;
      state.user_graph_data["user_name"] = action.payload.user_name;
      state.user_graph_data["user_mobile_number"] =
        action.payload.user_mobile_number;
      state.user_graph_data["user_email"] = action.payload.user_email;
      state.user_graph_data["user_status"] = action.payload.user_status;
      state.user_graph_data["user_active"] = action.payload.user_active;
    },
    // Set User Data
    setUserData(state, action) {
      state.user_data = action.payload;
    },
    // Set User Mobile Number
    setUserMobileNumber(state, action) {
      state.user_mobile_number = action.payload;
    },
    // Set User Data in Dashboard
    updateDashboardUserData(state, action) {
      const number = action.payload["user_mobile_number"];
      const updated_data = state.user_data.filter(
        (ele) => ele.user_mobile_number !== number
      );
      state.user_data = [...updated_data, action.payload];
      console.log(state.user_data);
    },
  },
});
export const {
  setTheme,
  setTotalUser,
  setTotalSite,
  setTotalSiteViews,
  setDashboardGraphLabels,
  setDashboardGraphData,
  setUserData,
  setUserMobileNumber,
  setSingleUserGraphLabels,
  setSingleUserGraphData,
  setSingleUserData,
  updateDashboardUserData,
} = sammetaSlice.actions;
export default sammetaSlice.reducer;

import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
import React from "react";
import { FaUsers } from "react-icons/fa6";
import { RiUserLocationFill } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";
import { useSelector } from "react-redux";

const TotalData = () => {
  const { theme, dark, light, total_user, total_sites, total_site_views } =
    useSelector((state) => state.sammetaReducer);
  return (
    <Grid w={"100%"} templateColumns="repeat(3, 1fr)" gap={4}>
      {/* Total User */}
      <GridItem
        h={{ base: "120px", lg: "150px" }}
        colSpan={{ base: 3, lg: 1 }}
        boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
        bg={theme === "dark" ? dark : light}
        color={!(theme === "dark") ? dark : light}
        borderRadius={"10px"}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Flex w={"70%"} p={5} flexDir={"column"}>
            <Text fontSize={{ base: "xl", lg: "3xl" }}>Total Users :</Text>
            <Heading>{total_user}</Heading>
          </Flex>
          <Flex w={"30%"}>
            <FaUsers size={"50%"} />
          </Flex>
        </Flex>
      </GridItem>
      {/* Total User */}
      <GridItem
        h={{ base: "120px", lg: "150px" }}
        colSpan={{ base: 3, lg: 1 }}
        boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
        bg={theme === "dark" ? dark : light}
        color={!(theme === "dark") ? dark : light}
        borderRadius={"10px"}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Flex w={"70%"} p={5} flexDir={"column"}>
            <Text fontSize={{ base: "xl", lg: "3xl" }}>Total Site Views :</Text>
            <Heading>{total_site_views}</Heading>
          </Flex>
          <Flex w={"30%"}>
            <RiUserLocationFill size={"50%"} />
          </Flex>
        </Flex>
      </GridItem>
      <GridItem
        h={{ base: "120px", lg: "150px" }}
        colSpan={{ base: 3, lg: 1 }}
        boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
        bg={theme === "dark" ? dark : light}
        color={!(theme === "dark") ? dark : light}
        borderRadius={"10px"}
      >
        <Flex
          w={"100%"}
          h={"100%"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
        >
          <Flex w={"70%"} p={5} flexDir={"column"}>
            <Text fontSize={{ base: "xl", lg: "3xl" }}>Total Sites :</Text>
            <Heading>{total_sites}</Heading>
          </Flex>
          <Flex w={"30%"}>
            <FaMapLocationDot size={"50%"} />
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default TotalData;

// Import the React library.
import React from "react";

// Import the Chart.js library.
import Chart from "chart.js/auto";

// Import the Doughnut component from the react-chartjs-2 library.
import { Doughnut } from "react-chartjs-2";
import { Flex, Heading } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const DashboardDoughnutChart = () => {
  const { theme, dark, light, dashboard_graph_data } = useSelector(
    (state) => state.sammetaReducer
  );

  // Chart Style
  Chart.defaults.color = "gray";
  Chart.defaults.borderColor = "rgba(154, 154, 154, 0.6)";

  // Define an array of labels.
  // const labels = ["Chennai", "Covai", "Namakkal", "Erode"];
  const labels = dashboard_graph_data.labels;

  // Defined an object
  const data = {
    labels,
    datasets: [
      {
        label: "Total Visits ",
        // data: [5, 20, 15, 2, 20, 30, 45],
        backgroundColor: dashboard_graph_data.backgroundColor,
        // borderColor: dashboard_graph_data.backgroundColor,
        data: dashboard_graph_data.data,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  // Return the Doughnut component, passing in the data object as a prop.
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      bg={theme === "dark" ? dark : light}
      color={!(theme === "dark") ? dark : light}
      p={{ base: 5, lg: 10 }}
      borderRadius={"10px"}
      boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
      flexDir={"column"}
      overflow={"hidden"}
    >
      <Heading
        w={"100%"}
        fontWeight={{ base: "500" }}
        fontSize={{ base: "xl", lg: "2xl" }}
      >
        Site View :
      </Heading>
      <Flex
        w={"100%"}
        h={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        bg={theme === "dark" ? dark : light}
        color={!(theme === "dark") ? dark : light}
        p={5}
        borderRadius={"10px"}
        flexDir={"column"}
      >
        <Doughnut
          data={data}
          options={options}
          // options={{ maintainAspectRatio: false }}
        />
      </Flex>
    </Flex>
  );
};

// Export the DoughnutChart component as the default export of the module.
export default DashboardDoughnutChart;

import {
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import vr_bg from "../../assets/vr_bg.jpg";
import logo_light from "../../assets/logo-light.png";
import logo_dark from "../../assets/logo-dark.png";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { setAdminLogin } from "../../Redux/Reducer/sammeta.reducer";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";

const AddNewSite = () => {
  // Navigation
  const navTo = useNavigate();
  // Store
  const { theme, dark, light, api_link } = useSelector(
    (state) => state.sammetaReducer
  );
  // Password show
  const [show, setShow] = React.useState(false);
  const dispatch = useDispatch();
  const [load, setLoad] = React.useState(false);
  const handleShow = () => setShow(!show);

  const fieldValidationSchema = yup.object({
    site_variable: yup.string().required("please site variable"),
    site_name: yup.string().required("please enter site name"),
  });
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      site_variable: "",
      site_name: "",
    },
    ValidationSchema: fieldValidationSchema,
    onSubmit: async (credential) => {
      try {
        setLoad(true);
        await axios
          .post(`${api_link}admin/add-new-site`, credential, {
            headers: {
              "x-auth-token": localStorage["sammeta-dashboard-admin-token"],
            },
          })
          .then((res) => {
            toast.success("Site Name Added", {
              style: {
                borderRadius: "10px",
                background: theme === "dark" ? dark : light,
                boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                color: !(theme === "dark") ? dark : light,
              },
            });
            values.site_name = "";
            values.site_variable = "";
            setLoad(false);
          })
          .catch((error) => {
            console.log("Error", error.response.data.message);

            toast.error(error.response.data.message);
            setLoad(false);
          });
      } catch (errors) {
        toast.error("Try Again");
        console.log("Error", errors);
        setLoad(false);
      }
    },
  });
  const check_login = () => {
    if (!localStorage["sammeta-dashboard-admin-token"]) {
      navTo("/");
    }
  };

  useEffect(() => {
    check_login();
  }, []);

  return (
    <Flex
      bg={theme === "dark" ? dark : "white"}
      color={!(theme === "dark") ? dark : light}
      minH={"50vh"}
      justifyContent={"center"}
    >
      <Flex
        w={{ base: "100vw", lg: "35vw" }}
        p={10}
        justifyContent={"center"}
        alignItems={"center"}
        flexDir={"column"}
      >
        <Image
          src={!(theme === "dark") ? logo_dark : logo_light}
          alt="logo"
          h={"10vh"}
        />
        <Stack p={3} />
        <form className="w-100" onSubmit={handleSubmit}>
          <Input
            id="site_variable"
            name="site_variable"
            pr="4.5rem"
            type="site_variable"
            placeholder="Site Variable"
            borderColor={
              errors.site_variable ? "red" : !(theme === "dark") ? dark : light
            }
            _focus={{ boxShadow: "0 0 0 0px pink", borderColor: "#808080" }}
            required={true}
            onChange={handleChange}
            value={values.site_variable}
          />
          <Stack p={3} />
          {/* <InputGroup size="md"> */}
          <Input
            id="site_name"
            name="site_name"
            pr="4.5rem"
            type="text"
            placeholder="Site Name"
            borderColor={
              errors.site_name ? "red" : !(theme === "dark") ? dark : light
            }
            _focus={{ boxShadow: "0 0 0 0px pink", borderColor: "#808080" }}
            required={true}
            onChange={handleChange}
            value={values.site_name}
          />
          {/* <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleShow} bg={"ghost"}>
                {!show ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement> */}
          {/* </InputGroup> */}
          <Stack p={3} />
          <Flex w={"100%"} justifyContent={"center"}>
            <Button
              colorScheme="pink"
              color={"white"}
              type="submit"
              isLoading={load}
            >
              Add New Site
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

export default AddNewSite;

import React from "react";
import {
  Menu,
  MenuItem,
  Sidebar,
  SubMenu,
  menuClasses,
} from "react-pro-sidebar";
import { Flex, Image } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import sidebar_bg from "../../../assets/sidebar_bg.jpg";

// Icons
import { MdOutlineDashboard } from "react-icons/md";
import { MdFormatColorFill } from "react-icons/md";
import { MdOutlineDarkMode } from "react-icons/md";
import { MdOutlineLightMode } from "react-icons/md";
import { LiaTableSolid } from "react-icons/lia";

// Logo
import logo_dark from "../../../assets/logo-dark.png";
import logo_light from "../../../assets/logo-light.png";
import logo_mini from "../../../assets/mini_logo.png";
import ThemeSwitch from "./ThemeSwitch";
import { Outlet, useNavigate } from "react-router-dom";
import Profile from "./Profile";
import {
  setDashboardGraphData,
  setDashboardGraphLabels,
  setTheme,
  setTotalSite,
  setTotalSiteViews,
  setTotalUser,
  setUserData,
} from "../../../Redux/Reducer/sammeta.reducer";
import axios from "axios";

const SideBar = () => {
  const { theme, dark, light, api_link } = useSelector(
    (state) => state.sammetaReducer
  );
  const themes = {
    light: {
      sidebar: {
        backgroundColor: "#ffffff",
        color: "#607489",
      },
      menu: {
        menuContent: "#fbfcfd",
        // icon: "#0098e5",
        icon: "#ff6b6b",
        hover: {
          backgroundColor: "#c5e4ff",
          color: "#44596e",
        },
        disabled: {
          color: "#9fb6cf",
        },
      },
    },
    dark: {
      sidebar: {
        //   backgroundColor: "#0b2948",
        backgroundColor: theme === "dark" ? dark : light,
        color: !(theme === "dark") ? dark : light,
      },
      menu: {
        menuContent: "#082440",
        // icon: "#59d0ff",
        icon: "#ff6b6b",
        hover: {
          backgroundColor: "#00458b",
          color: "#b6c8d9",
        },
        disabled: {
          color: "#3e5e7e",
        },
      },
    },
  };

  // hex to rgba converter
  const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  // State
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const navTo = useNavigate();
  const dispatch = useDispatch();
  // const hasImage = true;

  const menuItemStyles = {
    root: {
      fontSize: "16px",
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(
              themes[theme].menu.menuContent,
              theme === "dark" && !collapsed ? 0.4 : 1
            )
          : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes[theme].menu.hover.backgroundColor,
          theme === "dark" ? 0.8 : 1
        ),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  // Temp Code -----------------------------------

  const getalldata = async () => {
    await axios
      .get(`${api_link}admin/dashboard`, {
        headers: {
          "x-auth-token": localStorage["sammeta-dashboard-admin-token"],
        },
      })
      .then((res) => {
        const {
          total_site_views,
          total_user_count,
          total_site_count,
          labels,
          chart_data,
          user_data,
        } = res.data;
        dispatch(setTotalSiteViews(total_site_views));
        dispatch(setTotalUser(total_user_count));
        dispatch(setTotalSite(total_site_count));
        dispatch(setDashboardGraphLabels(labels));
        dispatch(setDashboardGraphData(chart_data));
        dispatch(setUserData(user_data));
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  React.useEffect(() => {
    if (!localStorage["sammeta-dashboard-admin-token"]) {
      navTo("/");
      return;
    }
    getalldata();
  }, []);

  return (
    <Flex minH={"100vh"} bg={theme === "dark" ? "#223140" : light}>
      <Sidebar
        className="border-0 shadow bg-white rounded"
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image={sidebar_bg}
        breakPoint="md"
        backgroundColor={hexToRgba(
          themes[theme].sidebar.backgroundColor,
          theme === "dark" ? 0.9 : 1
        )}
        rootStyles={{
          color: themes[theme].sidebar.color,
        }}
      >
        <Flex flexDir={"column"} h={"100%"}>
          <Flex justifyContent={"center"}>
            <Image
              p={2}
              my={5}
              src={
                collapsed
                  ? logo_mini
                  : theme === "dark"
                  ? logo_light
                  : logo_dark
              }
            />
          </Flex>
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu menuItemStyles={menuItemStyles}>
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<MdOutlineDashboard />}
                  onClick={() => {
                    navTo("/dashboard");
                  }}
                >
                  DashBoard
                </MenuItem>
              </Menu>
              <SubMenu label="Theme" icon={<MdFormatColorFill />}>
                <MenuItem
                  icon={<MdOutlineDarkMode />}
                  onClick={() => {
                    dispatch(setTheme("dark"));
                  }}
                >
                  {" "}
                  Dark
                </MenuItem>
                <MenuItem
                  icon={<MdOutlineLightMode />}
                  onClick={() => {
                    dispatch(setTheme("light"));
                  }}
                >
                  {" "}
                  Light
                </MenuItem>
              </SubMenu>
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem
                  icon={<LiaTableSolid />}
                  onClick={() => {
                    navTo("table");
                  }}
                >
                  User Management
                </MenuItem>
              </Menu>

              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem>
                  {/* <iframe
                    width="100%"
                    src="https://www.youtube.com/embed/WUWCU4ysPIw?si=wpNFQ1MJaumr9Pfn"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe> */}
                </MenuItem>
              </Menu>
              {/* <SubMenu label="Charts" icon={<GoGraph />}>
                <MenuItem> Pie charts</MenuItem>
                <MenuItem> Line charts</MenuItem>
                <MenuItem> Bar charts</MenuItem>
              </SubMenu>
              <SubMenu label="Maps">
                <MenuItem> Google maps</MenuItem>
                <MenuItem> Open street maps</MenuItem>
              </SubMenu>
              <SubMenu label="Theme">
                <MenuItem> Dark</MenuItem>
                <MenuItem> Light</MenuItem>
              </SubMenu>
              <SubMenu label="Components">
                <MenuItem> Grid</MenuItem>
                <MenuItem> Layout</MenuItem>
                <SubMenu label="Forms">
                  <MenuItem> Input</MenuItem>
                  <MenuItem> Select</MenuItem>
                  <SubMenu label="More">
                    <MenuItem> CheckBox</MenuItem>
                    <MenuItem> Radio</MenuItem>
                  </SubMenu>
                </SubMenu>
              </SubMenu>
              <SubMenu label="E-commerce">
                <MenuItem> Product</MenuItem>
                <MenuItem> Orders</MenuItem>
                <MenuItem> Credit card</MenuItem>
              </SubMenu> */}
            </Menu>

            {/* <Menu menuItemStyles={menuItemStyles}>
              <MenuItem>Calendar</MenuItem>
              <MenuItem>Documentation</MenuItem>
            </Menu> */}
          </div>
        </Flex>
      </Sidebar>
      <Flex w={"100%"} flexDir={"column"}>
        {/* NavBar */}
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          alignItems={"center"}
          h={"fit-content"}
          p={2}
          px={5}
          bg={theme === "dark" ? dark : light}
          color={!(theme === "dark") ? dark : light}
          boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
        >
          {broken ? (
            <HamburgerIcon
              fontSize={{ base: "4xl", lg: "5xl" }}
              p={2}
              onClick={() => setToggled(!toggled)}
              label="Collapse"
              _hover={{ cursor: "pointer", opacity: 0.8 }}
            />
          ) : (
            <HamburgerIcon
              fontSize={{ base: "4xl", lg: "5xl" }}
              p={2}
              onClick={() => setCollapsed(!collapsed)}
              label="Collapse"
              _hover={{ cursor: "pointer", opacity: 0.8 }}
            />
          )}
          <Flex alignItems={"center"}>
            <Profile />
            <ThemeSwitch />
          </Flex>
        </Flex>
        <Flex w={"100%"} p={5} flexDir={"column"}>
          <Outlet />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default SideBar;

import { Flex, Stack } from "@chakra-ui/react";
import React from "react";
import UpdateUserBarChart from "./UpdateUserBarChart";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  setSingleUserData,
  setSingleUserGraphData,
  setSingleUserGraphLabels,
} from "../../../Redux/Reducer/sammeta.reducer";
import UpdateUserCard from "./UpdateUserCard";

const UpdateUserAccess = () => {
  const { theme, dark, light, user_data, user_mobile_number, api_link } =
    useSelector((state) => state.sammetaReducer);
  const navTo = useNavigate();
  const dispatch = useDispatch();
  const getData = async () => {
    if (user_mobile_number) {
      await axios
        .post(
          `${api_link}admin/single-user-data`,
          { user_mobile_number },
          {
            headers: {
              "x-auth-token": localStorage["sammeta-dashboard-admin-token"],
            },
          }
        )
        .then((res) => {
          const labels = res.data.labels;
          const chart_data = res.data.chart_data;
          const user_data = res.data.user_data;
          // dispatch(setUserGraphData(res.data.chart_data));
          dispatch(setSingleUserGraphData(chart_data));
          dispatch(setSingleUserGraphLabels(labels));
          dispatch(setSingleUserData(user_data));
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } else {
      navTo("/dashboard/table");
    }
  };
  React.useEffect(() => {
    getData();
  }, []);
  return (
    <Flex w={"100%"} flexDir={{ base: "column", md: "row" }}>
      <Flex w={{ base: "100%", md: "60%", lg: "80%" }}>
        <UpdateUserBarChart />
      </Flex>
      <Stack p={2} />
      <Flex w={{ base: "100%", md: "40%", lg: "20%" }}>
        <UpdateUserCard />
      </Flex>
    </Flex>
  );
};

export default UpdateUserAccess;

import React from "react";
import MyTable from "./MyTable";
import { Flex } from "@chakra-ui/react";

const UserTable = () => {
  return (
    <Flex w={"100%"}>
      <MyTable />
    </Flex>
  );
};

export default UserTable;

import { Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  setSingleUserData,
  updateDashboardUserData,
} from "../../../Redux/Reducer/sammeta.reducer";

const UpdateUserCard = () => {
  const { theme, dark, light, user_graph_data, api_link } = useSelector(
    (state) => state.sammetaReducer
  );
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const handelAccess = async (number, access) => {
    setLoading(true);
    await axios
      .post(
        `${api_link}admin/update-access`,
        { user_mobile_number: number, user_active: access },
        {
          headers: {
            "x-auth-token": localStorage["sammeta-dashboard-admin-token"],
          },
        }
      )
      .then((res) => {
        toast.success("Access Updated");
        const updated_data = {
          user_name: user_graph_data.user_name,
          user_mobile_number: user_graph_data.user_mobile_number,
          user_email: user_graph_data.user_email,
          user_status: user_graph_data.user_status,
          user_active: access,
        };
        dispatch(setSingleUserData(updated_data));
        dispatch(updateDashboardUserData(updated_data));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Access Updated Failed");
        setLoading(false);
      });
  };
  React.useEffect(() => {}, [user_graph_data]);
  return (
    <Flex
      h={"100%"}
      w={"100%"}
      bg={theme === "dark" ? dark : light}
      color={!(theme === "dark") ? dark : light}
      p={5}
      borderRadius={"10px"}
      boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
      flexDir={"column"}
    >
      <Text color={"gray"} fontSize={{ base: "md", lg: "lg" }}>
        Name :{" "}
      </Text>
      <Heading fontSize={{ base: "lg", lg: "xl" }}>
        {user_graph_data.user_name.toUpperCase()}
      </Heading>
      <Text color={"gray"} fontSize={{ base: "md", lg: "lg" }}>
        Mobile :{" "}
      </Text>
      <Heading fontSize={{ base: "lg", lg: "xl" }}>
        {user_graph_data.user_mobile_number}
      </Heading>
      <Text color={"gray"} fontSize={{ base: "md", lg: "lg" }}>
        E-mail :{" "}
      </Text>
      <Heading fontSize={{ base: "lg", lg: "xl" }}>
        {user_graph_data.user_email || "NA"}
      </Heading>
      <Text color={"gray"} fontSize={{ base: "md", lg: "lg" }}>
        Acoount :
      </Text>
      <Heading fontSize={{ base: "lg", lg: "xl" }}>
        {user_graph_data.user_status ? "Verified" : "Not-Verified"}
      </Heading>
      <Text color={"gray"} fontSize={{ base: "md", lg: "lg" }}>
        Access :
      </Text>
      <Heading fontSize={{ base: "lg", lg: "xl" }}>
        {" "}
        {user_graph_data.user_active ? "Granted" : "Denied"}
      </Heading>
      <Stack p={2} />
      {!user_graph_data.user_active && (
        <Button
          bg={"green.300"}
          color={"white"}
          isLoading={loading}
          onClick={() => {
            handelAccess(user_graph_data.user_mobile_number, true);
          }}
        >
          Grant Access
        </Button>
      )}
      {user_graph_data.user_active && (
        <Button
          bg={"red.500"}
          color={"white"}
          isLoading={loading}
          onClick={() => {
            handelAccess(user_graph_data.user_mobile_number, false);
          }}
        >
          Deny Access
        </Button>
      )}
    </Flex>
  );
};

export default UpdateUserCard;

import React from "react";
import { useTable, usePagination } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Select,
  Link,
  Input,
  Stack,
  Heading,
  Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserMobileNumber } from "../../../Redux/Reducer/sammeta.reducer";

const MyTable = () => {
  const { theme, dark, light, user_data } = useSelector(
    (state) => state.sammetaReducer
  );
  const [data, setData] = React.useState(user_data);
  const navTo = useNavigate();
  const dispatch = useDispatch();
  dispatch(setUserMobileNumber(""));
  const handelVisit = (info) => {
    dispatch(setUserMobileNumber(info.user_mobile_number));
    navTo(`/dashboard/${info._id}`);
  };
  React.useEffect(() => {
    const new_data =
      user_data.length > 0 &&
      user_data.map((ele) => {
        const newObj = {
          ...ele,
          user_status: ele.user_status ? "Verified" : "Not Verified",
          user_name: ele.user_name.toUpperCase(),
          user_access: ele.user_active ? "Granted" : "Denied",
          user_profile_btn: (
            <Button
              w={"50%"}
              size={"sm"}
              bg={"blue.300"}
              color={"white"}
              onClick={() => {
                handelVisit(ele);
              }}
            >
              View
            </Button>
          ),
        };
        return newObj;
      });
    setData(new_data || []);
  }, [user_data]);
  const handelFilter = (val) => {
    const temp_data = user_data.map((ele) => {
      if ((ele.user_mobile_number + "").includes(val)) {
        const newObj = {
          ...ele,
          user_status: ele.user_status ? "Verified" : "Not Verified",
          user_name: ele.user_name.toUpperCase(),
          user_access: ele.user_active ? "Granted" : "Denied",
          user_profile_btn: (
            <Button
              w={"50%"}
              size={"sm"}
              bg={"blue.300"}
              color={"white"}
              onClick={() => {
                handelVisit(ele);
              }}
            >
              View
            </Button>
          ),
        };
        return newObj;
      }
      return false;
    });
    setData(temp_data.filter((ele) => ele !== false));
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Mobile",
        accessor: "user_mobile_number",
        id: "mobile",
      },
      {
        Header: "Name",
        accessor: "user_name",
        id: "name",
      },
      {
        Header: "Status",
        accessor: "user_status",
        id: "verified",
      },
      {
        Header: "Access",
        accessor: "user_access",
        id: "access",
      },
      {
        Header: "Info",
        accessor: "user_profile_btn",
        id: "butn",
      },
      // Add more columns as needed
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    gotoPage,
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 5 }, // Initial page and page size
    },
    usePagination
  );

  return (
    <Flex
      w={{ base: "700px", lg: "100%" }}
      overflowX={{ base: "scroll", lg: "auto" }}
      flexDir={"column"}
      bg={theme === "dark" ? dark : light}
      color={"gray"}
      justifyContent={"space-between"}
      p={5}
      borderRadius={"15px"}
      boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
    >
      <Heading color={!(theme === "dark") ? dark : light}>
        User Data Table
      </Heading>
      <Stack p={3} />
      <Input
        w={"50%"}
        placeholder="Search with mobile number"
        onChange={(e) => handelFilter(e.target.value)}
      />
      <Stack p={5} />
      <Table {...getTableProps()} minW={"700px"}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()} color={"gray"}>
                  {column.render("Header")}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Stack p={2} />
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        minW={"700px"}
        w={"100%"}
        px={5}
      >
        <Link
          _hover={{ textDecoration: "none" }}
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<< "}Previous Page
        </Link>

        <Flex>
          Page {pageIndex + 1} of {Math.ceil(data.length / pageSize)}
        </Flex>
        <Flex w={"fit-content"} alignItems={"center"}>
          <Flex>Go to page: </Flex>
          <Stack p={2} />
          <Input
            w={"50px"}
            type="number"
            defaultValue={pageIndex + 1}
            _focus={{
              boxShadow: "0 0 0 0px pink",
              borderColor: "gray",
            }}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </Flex>
        <Select
          border={0}
          w={"10%"}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
          _focus={{
            boxShadow: "0 0 0 0px pink",
            border: 0,
            borderColor: "#808080",
          }}
        >
          {[5, 10, 15].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </Select>
        <Link
          _hover={{ textDecoration: "none" }}
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next Page {">>"}
        </Link>
      </Flex>
    </Flex>
  );
};

export default MyTable;

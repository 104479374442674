import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosLogOut } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Profile = () => {
  const { theme, dark, light } = useSelector((state) => state.sammetaReducer);
  const navTo = useNavigate();
  const logout = () => {
    localStorage.removeItem("sammeta-dashboard-admin-token");
    toast("Logged Out!", {
      icon: <IoIosLogOut />,
      style: {
        borderRadius: "10px",
        background: theme === "dark" ? dark : light,
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        color: !(theme === "dark") ? dark : light,
      },
    });
    navTo("/");
  };
  return (
    <Stack>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<>Admin</>}
          bg={"transparent"}
          color={!(theme === "dark") ? dark : light}
          _hover={{ opacity: 0.7 }}
          _active={{ bg: theme === "dark" ? dark : light }}
        />
        <MenuList
          boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
          className="border-0"
          bg={theme === "dark" ? dark : light}
          color={!(theme === "dark") ? dark : light}
        >
          <MenuItem
            bg={theme === "dark" ? dark : light}
            color={"red.500"}
            icon={<IoIosLogOut />}
            onClick={logout}
          >
            LogOut
          </MenuItem>
        </MenuList>
      </Menu>
    </Stack>
  );
};

export default Profile;

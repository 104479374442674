import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../../Redux/Reducer/sammeta.reducer";

import { MoonIcon, SunIcon } from "@chakra-ui/icons";

const ThemeSwitch = () => {
  const { theme, dark, light } = useSelector((state) => state.sammetaReducer);
  const dispatch = useDispatch();
  return (
    <>
      {theme === "dark" ? (
        <MoonIcon
          color={!(theme === "dark") ? dark : light}
          fontSize={{ base: "xl", lg: "2xl" }}
          onClick={() => {
            dispatch(setTheme("light"));
          }}
          mx={3}
          _hover={{ cursor: "pointer", opacity: 0.8 }}
        />
      ) : (
        <SunIcon
          color={!(theme === "dark") ? dark : light}
          fontSize={{ base: "xl", lg: "2xl" }}
          onClick={() => {
            dispatch(setTheme("dark"));
          }}
          mx={3}
          _hover={{ cursor: "pointer", opacity: 0.8 }}
        />
      )}
    </>
  );
};

export default ThemeSwitch;

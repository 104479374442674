// Import the React library.
import React from "react";

// Import the Chart.js library.
import Chart from "chart.js/auto";

// Import the Bar component from the react-chartjs-2 library.
import { Bar } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";

const DashboardBarChart = () => {
  const { theme, dark, light, dashboard_graph_data } = useSelector(
    (state) => state.sammetaReducer
  );

  // Chart Style
  Chart.defaults.color = "gray";
  Chart.defaults.borderColor = "rgba(154, 154, 154, 0.6)";

  // Define an array of labels.
  // const labels = ["Chennai", "Covai", "Namakkal", "Erode"];
  const labels = dashboard_graph_data.labels;

  // Defined an object
  const data = {
    labels,
    datasets: [
      {
        label: "Total Visits ",
        // backgroundColor: "rgb(255, 99, 132)",
        // borderColor: "rgb(255, 99, 132)",
        backgroundColor: dashboard_graph_data.backgroundColor,
        borderColor: dashboard_graph_data.backgroundColor,
        // data: [5, 20, 15, 2, 20, 30, 45],
        data: dashboard_graph_data.data,
      },
    ],
  };

  // Return the Bar component, passing in the data object as a prop.
  return (
    <Flex
      w={"100%"}
      h={"100%"}
      bg={theme === "dark" ? dark : light}
      p={5}
      borderRadius={"10px"}
      boxShadow={"0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
    >
      <Bar data={data} options={{ maintainAspectRatio: false }} />
    </Flex>
  );
};

// Export the BarChart component as the default export of the module.
export default DashboardBarChart;

import { Flex } from "@chakra-ui/react";
import React from "react";
import TotalData from "./TotalData";
import DashboardBarChart from "./DashboardBarChart";
import DashboardDoughnutChart from "./DashboardDoughnutChart";

const DashBoardComp = () => {
  return (
    <>
      <Flex p={5} w={"100%"}>
        <TotalData />
      </Flex>
      <Flex w={"100%"} flexDir={{ base: "column", lg: "row" }}>
        <Flex
          h={"450px"}
          w={{ base: "100%", lg: "60%" }}
          justifyContent={"center"}
          alignItems={"center"}
          p={5}
        >
          <DashboardBarChart />
        </Flex>
        <Flex
          h={"450px"}
          w={{ base: "100%", lg: "40%" }}
          justifyContent={"center"}
          alignItems={"center"}
          p={5}
        >
          <DashboardDoughnutChart />
        </Flex>
      </Flex>
    </>
  );
};

export default DashBoardComp;

import { Route, Routes } from "react-router-dom";
import "./App.css";
import AdminLogin from "./pages/Admin/AdminLogin";
import "bootstrap/dist/css/bootstrap.min.css";
import DashBoardComp from "./pages/Admin/DashBoardComp/DashBoardComp";
import SideBar from "./pages/Admin/DashBoardComp/SideBar";
import UserTable from "./pages/Admin/TableComp/UserTable";
import AddNewSite from "./pages/Admin/AddNewSite";
import UpdateUserAccess from "./pages/Admin/UpdateUserComp/UpdateUserAccess";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<AdminLogin />} />
        <Route path="/dashboard" element={<SideBar />}>
          <Route path="" element={<DashBoardComp />} />
          <Route path="table" element={<UserTable />} />
          <Route path="add-new-site" element={<AddNewSite />} />
          <Route path=":mobile" element={<UpdateUserAccess />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
